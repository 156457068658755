<template>
    <div>
        <img class="example-headImg" src="../assets/image/aboutus/07-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">加入我们</div>
            <div class="head-explain-content">携手打造智慧世界</div>
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <div class="joinus-title1">常招职位</div>
            <div class="joinus-title2">比孚员工拥抱新技术，抓住机遇，推动行业进步。欢迎斗志昂扬、才华横溢、渴望创新的员工加入比孚。</div>
            <div class="joinus-box">
                <div class="card">
                    <div class="title1">SAP销售</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">数据销售</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">客户经理/客户总监</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">产品经理</div>
                    <div class="title2">工作地点: 无锡</div>
                </div>
                <div class="card">
                    <div class="title1">SAP项目经理</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">Java项目经理</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">产品经理（供应链）</div>
                    <div class="title2">工作地点: 上海</div>
                </div>
                <div class="card">
                    <div class="title1">销售（食品饮料行业）</div>
                    <div class="title2">工作地点: 无锡</div>
                </div>
            </div>
            <div class="email-title">如果您对以上岗位有兴趣，请联系公司HR：<div class="email-title-1">HR@bizfocus.cn</div></div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'比孚,上海比孚,比孚信息,比孚信息科技,SAP销售,数据销售,客户经理/客户总监,产品经理,SAP项目经理,Java项目经理,产品经理（供应链）,销售（食品饮料行业）' },
        { name:'description', content:'上海比孚信息科技有限公司（以下简称“比孚”）是一家成立于2013年的专注于IT技术服务的高新技术企业。比孚核心团队成员来自多家国际知名咨询和信息技术服务公司，在toB信息化咨询领域有着丰富的管理和落地经验。随着数字化转型的深入以及IT技术的快速发展，企业的IT投入逐年升高，IT的ROI显得更加重要。面对不同行业不同业务的业务和技术需求，比孚凭借积累多年的方法论和工具，始终围绕着价值和效率，为客户提供专业，快速见效的解决方案。为向客户提供更具针对性的专业服务，比孚将技术服务整合为数据服务、SAP服务和技术开发服务三大服务板块，提供从咨询设计到开发落地运维的完整技术服务。业务上，比孚聚焦于快消零售，制造以及医疗三大行业，不断研发和打磨针对行业的解决方案和产品，帮助企业以更低成本更好效果更短时间内实现业务流程的数字化转型，提升业务效率，减少人工，优化成本。' }
        ]
    },
    data() {
        return {
        };
    },

    mounted() {
        this.activeIndex = '7'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.joinus-title1{
    // height: 3.6rem;
    font-size: 2.4rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #333333;
    line-height: 3.6rem;

    margin-top: 5.2rem;
}
.joinus-title2{
    height: 3.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #333333;
    line-height: 3.6rem;

    margin-top: 1rem;
}
.joinus-box{
    width: 140rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 1rem 0 0 26rem;
    .card{
        width: 34rem;
        height: 12rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 1rem 0px #E7E7E7;
        border-top: 3px solid #11A84F;

        margin-top: 4.1rem;
        transition: all .5s;
    }
    .card:hover{
        transform: scale(1.05);
        transition: all .5s;
    }
    .title1{
        height: 3.6rem;
        font-size: 2.4rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.6rem;
        margin-top: 2.7rem;
    }
    .title2{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.2rem;
        margin-top: 0.4rem;
    }
}
.email-title{
    height: 3.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    line-height: 3.6rem;
    display: flex;
    margin: 4.5rem 0 0 75.3rem;
}
.email-title-1{
    height: 4.6rem;
    font-size: 1.6rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 3rem;
    }
</style>
